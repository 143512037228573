import React from 'react';
import {Link} from 'gatsby';

import BlogLayout from '../../components/BlogLayout';

const Greetings = () => (
  <BlogLayout title="Faroese Greetings and Salutations" description="Want to know how to say hello in Faroese? These greetings and salutations are essential.">
    <h1>Faroese Greetings</h1>
    <div className="table-wrap">
    <table>
      <thead>
        <tr>
          <th>Faroese</th>
          <th>Transliteration</th>
          <th>English</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Góðan morgun</td>
          <td>gouwan morgun</td>
          <td>Good morning</td>
        </tr>
        <tr>
          <td>Góðan dag</td>
          <td>gouwan dae</td>
          <td>Good day</td>
        </tr>
        <tr>
          <td>Gott kvøld</td>
          <td>gott kvuld</td>
          <td>Good evening</td>
        </tr>
        <tr>
          <td>Góða nátt</td>
          <td>gouwa not</td>
          <td>Good night</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div className="navigation">
    <Link to="/vocabulary/days-of-the-week/">Days of the week</Link>
    </div>
  </BlogLayout>
);

export default Greetings;
