import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const BlogLayout = ({children, title, description}) => {
    return (
       <>
            <Helmet
              title={title + `: ` + `Faroese Online`}
              meta={[
                { name: 'description', content: description },
                { name: 'keywords', content: 'faroese, language, icelandic' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <Wrapper>
              {children}
            </Wrapper>
          </>
        )}

export default BlogLayout;

const Wrapper = styled.div`
background: #f4f4f4;
width:100%;
height:100%;
min-height:75vh;
max-width:740px;
margin:auto;
padding:20px;

h1 {
font-size:2rem;
text-align:center;
}
p, ul, ol, h1, h2, h3, h4, table {
  color: #333333;
}
a {
  color: #2e7430;
  &:hover {
    color: #8bc34a;
  }
}
table {
  margin:auto;
}
td, th {
  vertical-align: middle;
    text-align: center;
    border: 3px solid #2e7430;
    padding: .75rem;
}
thead {
  border: 3px solid #2e7430;
  background-color: #8bc34a;
  font-weight:bold;
    color: #fff;
    text-transform: uppercase;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
}
tbody tr:nth-of-type(odd) {
  background-color: #eef5ee;
}
.table-wrap {
  display: block;
    width: 100%;
    overflow-x: auto;
}
.navigation {
  text-align:center;
  margin-top:20px;
  a:after {
    content: ' →';
  }
}
`